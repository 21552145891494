<template>
  <section>
    <div>
      <ul class="hubpage">
        <h1 class="w100 txt_c" >고객사를 선택해주세요</h1>
        <h3 class="w100 txt_c mgt10" >해당 고객사의 관리자 페이지로 이동합니다.</h3>

        <li v-for="item in mngRouteLinkDataSource" :class="getCmpyClass(item.sysGbn)"
            @click="goRouter(item.sysGbn, item)">
          <img v-if="item.sysGbn !== customCmpyConstant.fms"
               :src="getCustomImgSrc(item.sysGbn)">
          {{ item.mngCmpyNm }}
        </li>
      </ul>
    </div>
  </section>

</template>

<script>
import { mapGetters } from 'vuex'
import Constant from '@/common/constant'
export default {
  name: 'SelectCompany',
  computed :{
    ...mapGetters(['loginUserData', 'loginUserToken'])
  },
  created () {
    const vm = this
    vm.mngRouteLinkDataSource = [
      {sysGbn: '', mngCmpyNm : 'Portal 메인'},
      ...vm.loginUserData.firstAceessInfoCmpyMng,
    ]
  },
  methods : {
    getCmpyClass : function (sysGbn){
      const vm = this
      switch (sysGbn) {
        case vm.customCmpyConstant.portal :
          return 'potal'
        case vm.customCmpyConstant.efs :
          return 'admin_page'
        case vm.customCmpyConstant.fms :
          return ''
        case vm.customCmpyConstant.mega :
          return 'mega'
        case vm.customCmpyConstant.cd :
          return 'cd'
        case vm.customCmpyConstant.visang :
          return 'visang'
      }
    },
    getCustomImgSrc : function (sysGbn){
      const vm = this
      switch (sysGbn) {
        case vm.customCmpyConstant.portal :
          return require('../../assets/img/etners-main/icon_efs.png')
        case vm.customCmpyConstant.efs :
          return require('../../assets/img/etners-main/icon_admin.png')
        case vm.customCmpyConstant.mega :
          return require('../../assets/img/etners-main/icon_mega.png')
        case vm.customCmpyConstant.cd :
          return require('../../assets/img/etners-main/icon_cd.png')
        case vm.customCmpyConstant.visang :
          return require('../../assets/img/etners-main/icon_visang.png')
      }
    },
    goRouter: function (sysGbn, item) {

      const vm = this
      let mode = location.hostname.includes('dev') || location.hostname.includes('localhost') ? 'dev-' : ''
      let linkCmpyCd
      let domain
      switch (sysGbn) {
        case vm.customCmpyConstant.portal :
          domain = `${mode === 'dev-' ? 'dev-efs.' : ''}etnersefs.com`
          break
        case vm.customCmpyConstant.efs :
          vm.goDashBoard()
          break
        case vm.customCmpyConstant.fms :
          domain = `${mode}fms.etnersefs.com`
          linkCmpyCd = item.mngCmpyCd
          break
        case vm.customCmpyConstant.mega :
          domain = `${mode}mega.etnersefs.com/lgn/getSSOLogin.data`
          break;
        case vm.customCmpyConstant.cd :
          domain = `${mode}cd.etnersefs.com/lgn/getSSOLogin.data`
          break;
        case vm.customCmpyConstant.visang :
          domain = `${mode}visang.etnersefs.com/lgn/getSSOLogin.data`
          break;
      }

      if(!([vm.customCmpyConstant.efs, vm.customCmpyConstant.portal, vm.customCmpyConstant.fms].includes(sysGbn))){
        window.open(`https://${domain}?loginUserToken=${vm.loginUserToken}`)
      }else if(vm.customCmpyConstant.fms === sysGbn){
        window.location.href = `https://${domain}?loginUserToken=${vm.loginUserToken}${!!linkCmpyCd? '&linkCmpyCd='+linkCmpyCd : ''}`
      }else if(vm.customCmpyConstant.portal === sysGbn){
        window.location.href = `https://${domain}`
      }
    },
    // 대시보드로 이동
    goDashBoard() {
      this.$router.push({
        name: 'DashBoard',
      })
    },
  },
  data () {
    return {
      Constant : Constant,
      mngRouteLinkDataSource : [],
      customCmpyConstant : {
        portal : '',
        efs : '99999',
        fms : '01',
        mega : '02',
        cd : '03',
        visang : '04'
      }
    }
  },
}
</script>

<style scoped>

</style>
